<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-add-wrapper mb-2">
          <b-alert variant="danger" :show="arrival === undefined">
            <h4 class="alert-heading">Ошибка получения данных</h4>
            <div class="alert-body">
              Не найдено прихода с таким ID. Проверьте
              <router-link class="alert-link" :to="{ name: 'arrivals'}">Список приходов</router-link>
              чтобы увидеть другие приходы.
            </div>
          </b-alert>
          <div v-if="arrival" class="row invoice-add">
            <div class="col-12">
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding pb-0">
                  <h4 class="invoice-title mb-1">Приход #{{ arrival.id }}</h4>
                  <div class="row">
                    <div class="col-12 text-right">
                      <router-link
                          v-if="arrival.id"
                          class="btn btn-outline-secondary mr-2"
                          :to="{ name: 'arrival-details', params: { id: arrival.id }}"
                      >
                        Назад
                      </router-link>
                      <button class="btn btn-primary" @click="updateArrival">
                        Сохранить
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6">
                      <label class="title">Поставщик:</label>
                      <v-select
                          v-if="suppliers.length > 0"
                          v-model="arrival.user"
                          :options="suppliers"
                          :disabled="suppliers.length === 1"
                          label="surname"
                          :get-option-label="(option) => option.name + ' ' + option.surname"
                      >
                        <template #option="{ name, surname, company_name }">
                          <span> {{ name }} {{ surname }} {{ company_name && `(${company_name})` }}</span>
                        </template>
                      </v-select>
                    </div>
                    <div class="form-group col-6">
                      <label class="title">Статус:</label>
                      <v-select
                          v-if="statuses.length > 0"
                          v-model="arrival.status"
                          :options="statuses"
                          :disabled="statuses.length === 1"
                          label="name"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-3">
                      <label class="title">Валюта</label>
                      <v-select
                          v-if="currencies.length > 0"
                          v-model="arrival.currency"
                          :options="currencies"
                          :disabled="currencies.length === 1"
                          label="name"
                      >
                      </v-select>
                    </div>
                    <div class="col-12 col-lg-3" v-if="$can('manage', 'arrivals')">
                      <label class="title">Цена за кг</label>
                      <input class="form-control" v-model="pricePerWeight" @keyup="setPrice('weight')">
                    </div>
                    <div class="col-12 col-lg-3" v-if="$can('manage', 'arrivals')">
                      <label class="title">Цена за штуку</label>
                      <input class="form-control" v-model="pricePerPiece" @keyup="setPrice('piece')">
                    </div>
                    <div class="col-12 col-lg-3">
                      <label class="title">Индекс хранения</label>
                      <input class="form-control" v-model="storageIndex" @keyup="setStorage()">
                    </div>
                  </div>
                  <hr class="invoice-spacing">
                  <h6 class="mb-1">Выбор товара</h6>
                  <div class="row">
                    <div class="col-6">
                      <label class="d-inline d-lg-none">Товар</label>
                      <v-select
                          v-model="selectedProductSpecification"
                          :options="products"
                          :filterable="false"
                          label="product_id"
                          :disabled="!arrival.user"
                          class="item-selector-title"
                          placeholder="Выберите товар"
                          @search="searchProduct"
                      >
                        <template #selected-option="{ size_name, product }">
                          {{ product.sku }} - Размер {{ size_name }}
                        </template>
                        <template #option="{ size_name, qty, condition_name, product }">
                          {{ product.sku }} - Размер {{ size_name }} - {{ condition_name }}<br>
                          <small>{{ product.name }} {{ product.brand_name }}</small><br>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-6">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          :disabled="!arrival.user"
                          @click="addProduct"
                      >
                        Добавить товар
                      </b-button>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding form-item-section pt-0">
                  <div class="position-relative table-responsive">
                    <table class="table b-table">
                      <thead>
                      <tr>
                        <th>Брак</th>
                        <th>Индекс хранения</th>
                        <th>Товар</th>
                        <th>Фото</th>
                        <th>Состояние</th>
                        <th>Вес</th>
                        <th v-if="$can('manage', 'arrivals')">Цена</th>
                        <th>К-во</th>
                        <th v-if="$can('manage', 'arrivals')">Сумма</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in arrival.items" :key="index">
                        <td>
                          <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="item.defected"
                                value="1"
                                id="item.id"
                            >
                            <label class="custom-control-label" for="item.id"></label>
                          </div>
                        </td>
                        <td><input v-model="item.storage_index" class="form-control"></td>
                        <td>
                          <b>{{ item.product_specification.product.sku }} <br>
                            Размер {{ item.product_specification.size_name }}</b><br>
                          <small>
                            {{ item.product_specification.product.name }}
                            {{ item.product_specification.product.brand_name }}
                          </small><br>
                          <small v-if="item.product_specification.set_qty > 1">
                            Набор: {{ item.product_specification.set_qty }}шт
                          </small>
                        </td>
                        <td>
                          <img
                              v-if="item.product_specification.product.small_main_image"
                              :src="`/img/product/${item.product_specification.product.small_main_image}`"
                              class="product-img"
                              alt=""
                          >
                        </td>
                        <td>{{ item.product_specification.condition_name }}</td>
                        <td>{{ item.product_specification.weight / 1000 }}</td>
                        <td v-if="$can('manage', 'arrivals')">
                          <input v-model="item.cost" class="form-control input-cost">
                        </td>
                        <td>
                          <input
                              type="number"
                              v-model="item.qty"
                              :max="item.product_specification.max_qty"
                              class="form-control input-qty"
                          >
                        </td>
                        <td v-if="$can('manage', 'arrivals')">
                          {{ item.cost * item.qty || 0 }}
                        </td>
                        <td>
                          <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                          />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
                <b-card-body class="invoice-padding pb-0">
                  <div class="row">
                    <div class="col-12 col-md-6 order-2 order-md-1 mt-md-0 mt-3 d-flex align-items-center"></div>
                    <div class="col-12 col-md-6 order-1 order-md-2 mt-md-6 d-flex justify-content-end">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Количество:</p>
                          <p class="invoice-total-amount">{{ summary.qty }}</p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Вес (кг):</p>
                          <p class="invoice-total-amount">{{ summary.weight / 1000 }}</p>
                        </div>
                        <hr class="my-50">
                        <div class="invoice-total-item" v-if="$can('manage', 'arrivals')">
                          <p class="invoice-total-title">Сумма:</p>
                          <p class="invoice-total-amount">{{ summary.cost.toFixed(2) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding pt-0">
                  <div class="row">
                    <div class="col-12 text-right">
                      <router-link
                          v-if="arrival.id"
                          class="btn btn-outline-secondary mr-2"
                          :to="{ name: 'arrival-details', params: { id: arrival.id }}"
                      >
                        Назад
                      </router-link>
                      <button class="btn btn-primary" @click="updateArrival">
                        Сохранить
                      </button>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BCard,
  BCardBody,
  BButton,
  BAlert,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { toInteger } from '@/helpers/formatters';

export default {
  name: 'ArrivalEdit',
  components: {
    BCard,
    BCardBody,
    BButton,
    BAlert,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      suppliers: [],
      statuses: [],
      currencies: [],
      selectedProductSpecification: null,
      products: [],
      arrival: {
        user: null,
        status: null,
        items: [],
        comments: null,
        currency: null,
        client_order: null,
        client_price: null,
      },
      pricePerWeight: null,
      pricePerPiece: null,
      storageIndex: null,
    };
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'arrival.items': function () {
      this.initTrHeight();
    },
  },
  async mounted() {
    await this.getSuppliers();
    await this.getArrivalStatuses();
    await this.getCurrencies();
    await this.getArrivalInfo();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    async getSuppliers() {
      this.suppliers = (await this.$api.users.suppliers()).data;
    },
    async getCurrencies() {
      this.currencies = (await this.$api.currencies.list()).data;
    },
    async getArrivalInfo() {
      try {
        this.arrival = (await this.$api.arrivals.get(this.$route.params.id)).data;

        if (this.arrival.status.id === 2 && !this.$can('manage', 'arrivals')) {
          await this.$router.push({ name: 'arrival-details', params: { id: this.arrival.id } });
        }
      } catch (e) {
        this.arrival = undefined;
      }
    },
    async updateArrival() {
      try {
        await this.$api.arrivals.update(this.arrival.id, { ...this.arrival });
        this.$toast.success('Приход успешно обновлен');
        await this.$router.push({ name: 'arrival-details', params: { id: this.arrival.id } });
      } catch (e) {
        this.$toast.error(e.response.data);
      }
    },
    async searchProduct(query, loading) {
      if (query.length && this.arrival.user) {
        loading(true);
        this.$api.products.searchAll({ search: query }).then((response) => {
          this.products = response.data;
          loading(false);
        });
      }
    },
    async getArrivalStatuses() {
      this.statuses = (await this.$api.arrivals.statuses()).data;
      this.arrival.status = this.statuses.find((status) => status.id === 2);
    },
    addProduct() {
      this.arrival.items.unshift({
        product_specification: this.selectedProductSpecification,
        cost: null,
        qty: 1,
        defected: 0,
        storage_index: null,
      });
    },
    removeItem(index) {
      this.arrival.items.splice(index, 1);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },
    setPrice(type) {
      if (type === 'weight') {
        this.arrival.items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.cost = ((toInteger(this.pricePerWeight) * item.product_specification.weight) / 100000).toFixed(2);
        });
      } else {
        this.arrival.items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.cost = this.pricePerPiece;
        });
      }
    },
    setStorage() {
      this.arrival.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.storage_index = this.storageIndex;
      });
    },
  },
  computed: {
    summary() {
      return {
        qty: this.arrival.items.reduce((acc, item) => parseInt(acc, 10) + parseInt(item.qty, 10), 0),
        cost: this.arrival.items.reduce((acc, item) => acc + item.cost * item.qty, 0),
        weight: this.arrival.items.reduce((acc, item) => acc + item.product_specification.weight * item.qty, 0),
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.input-qty {
  min-width: 80px;
}

.input-cost {
  min-width: 100px;
}

.product-img {
  max-height: 60px;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.invoice-edit-input {
  max-width: 100% !important;
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
.custom-checkbox {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}
</style>
